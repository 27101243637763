$blanc: #ffffff;
$noir: #323338;
// $vert: #e8f3ef;
$violet: #a100fe;
$light: #676879;
$rouge: #ff0000;

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }