html > body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background: var(--primary-background-color);
    color: var(--primary-text-color);
    font-size: 15px;
    line-height: normal;

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    .vr {
        border-right: 1px solid var(--layout-border-color);
    }

    #root {
        position: relative;
    }
    .App {
        svg:not(:root) {
            top: 3px;
        }
        align-items: center;
        justify-content: center;
        margin: 20px;
        position: relative;

        .empty {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            
            .inner {
                display: flex;
                flex-direction: column;
                align-items: center;

                > div {
                    animation: flickerAnimation 2s infinite;
                }
            }
        }

        .overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            position: fixed;
            z-index: 10;
            top: 0;
            background: rgba($blanc,0.9);
            width: 100%;

            .inner {
                display: flex;
                flex-direction: column;
                align-items: center;

                > div {
                    animation: flickerAnimation 2s infinite;
                }
            }
        }

        .content {
            .top-bar {
                font-size: 14px;
                a {
                    font-size: 14px;
                    color: $noir;
                }
                .active {
                    a {
                        font-weight: bold;
                    }
                }
            }

            .title-filtres {
                margin: 20px 0 10px;
            }

            .nav-bar {
                margin: 10px 0 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .rs-picker-toggle-wrapper, .react-datepicker-wrapper {
                    display: block;
                }

                .filters {
                    display: flex;
                    align-items: inherit;
                    
                    strong {
                        font-size: 1.1rem;
                        // margin-right: 20px;
                    }

                    input {
                        height: 36px;
                        border: 1px solid #949494;
                        border-radius: 6px;
                    }
                    .filter-option {
                        margin: 0  20px 0 0;
                    }

                    .button-filter {
                        height: 36px;
                        background-color: black;
                        color: white;
                        font-size: 12px;
                        max-width: 10%;
                        border-radius: 5px;
                        margin-top: 12px;
                        cursor: pointer;
                        &:disabled {
                          color: grey;
                          opacity: 0.7;
                          cursor: default;
                        }
                    }
                }

                .rs-picker-toggle-value {
                    color: $violet;
                    border-color: $light;
                }
                .rs-picker-select {
                    .rs-picker-toggle-clean {
                        display: none;
                    }
                }
            }


            section {
                margin-bottom: 30px;
                .projet {
                    &:not(:last-child){
                        margin-bottom: 30px;
                    }
                }

                table {
                    width: 100%;
                    table-layout: fixed;

                    thead th {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                        background: white;
                    }
                    
                    thead button {
                        background-color: transparent;
                        display: inline-flex;
                        align-items: center;
                    }
                    thead button::after {
                        content: url('../../assets/img/tri.png');
                        padding: 0 10px;
                      }
                      
                    thead button.ascending::after {
                        content: url('../../assets/img/tri-down.png');
                      }
                      
                      thead button.descending::after {
                        content: url('../../assets/img/tri-up.png');
                      }
                    tbody, thead {
                        background: none;

                        tr {
                            border-left: 1px solid rgb(208, 212, 228);
                            td {
                                background: none;
                                text-align: left;
                                color: $noir;
                                border-bottom: solid 1px rgb(208, 212, 228);
                                // &.td-tache {
                                //     width: 25%;
                                // }
                                // &.td-stache {
                                //     width: 25%;
                                // }
                                // &.td-temps {
                                //     width: 50%;
                                // }
                                // &.td-temps-nom {
                                //     width: 60%;
                                // }
                                // &.td-temps-date {
                                //     width: 30%;
                                // }
                                // &.td-temps-tps  {
                                //     width: 10%;
                                // }

                                &.right {
                                    text-align: right;
                                }

                                &.total {
                                    color: $violet;
                                    font-weight: bold;
                                }

                            }

                            td:not(:chiffre) {
                                padding-left: 10px;
                            }

                            .chiffre {
                                text-align: center;
                            }
                        }
                        
                        
                    }
                }
            }

            .pliable {

                .subtable {
                    display: none;
                }
                .open {
                    background-color: rgba($noir,0.15);

                     .subtable {
                        display: block;
                        margin-top: 10px;
                    }
                }
            }
            .titleTime {
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 36px;
                outline: 1px solid $light;

                .icon {
                    display: flex;
                    align-items: center;
                }

                h3 {
                    > svg:first-child {
                        margin-right: 5px;
                    }
                    > svg:not(:first-child) {
                        margin: auto 10px auto 5px;
                        transform: rotate(90deg);
                    }
                }
                h4 {
                    > svg:first-child {
                        margin-right: 5px;
                    }
                    > svg:not(:first-child) {
                        margin: auto 10px auto 5px;
                        transform: rotate(90deg);
                    }
                }

                > strong {
                    padding-right: 5px;
                }

                & + .blockTime {
                    display: none;
                    margin-left: 40px;
                    margin-right: 20px;
                    // border: red dashed 1px;
                }

                .open {
                    background-color: rgba($noir,0.15);

                    & + .blockTime {
                        display: block;
                        margin-top: 10px;

                        > table {
                            margin-bottom: 15px;
                        }
                    }
                }

                &.open {
                    background-color: rgba($noir,0.15);

                    & + .blockTime {
                        display: block;
                        margin-top: 10px;

                        > table {
                            margin-bottom: 15px;
                        }
                    }
                }

                .col-title {
                    display: flex;
                    width: 20%;
                    height: 36px;
                    outline: 1px solid rgb(208, 212, 228);
                    align-items: center;
                }
                .col-info {
                    display: flex;
                    width: 15%;
                    height: 36px;
                    justify-content: end;
                    outline: 1px solid rgb(208, 212, 228);
                    align-items: center;
                    justify-content: center;
                }
                .col-deco {
                    display: flex;
                    width: 60%;
                    justify-content: end;
                    padding-left: 10px;
                    height: 36px;
                    outline: 1px solid rgb(208, 212, 228);
                    align-items: center;
                    justify-content: center;
                }
                .neg{
                    color: red;
                }
                .pos{
                    color: green;
                }
                .lib-col {
                    color: rgb(103, 104, 121);
                    justify-content: center;
                }
            }
            
        }

        .monday-style-checkbox {
            margin-top: 15px;
            svg:not(:root) {
                top: 0px;
            }
        }

    }


.modal-button {
    margin-top: 11px;
    background-color: transparent;
    img {
        height: 15px;
        width: 15px;
        margin-bottom: 3px;
    }
}
.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.5em 1.5em 1.5em 1.5em;
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  ._modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._modal-close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .modal-body {
    padding: 0.25em 30px 30px;

    input, textarea, .dropdown-wrapper  {
        border: 1px solid $light !important;
        border-radius: 6px !important;
        display: block;
        margin: 5px 0 20px;
        width: 100%;
        padding: 5px;
    }
    .dropdown-wrapper  {
        padding: 0;
    }

    input {
        height: 36px;
    }

    .input-component {
        height: 55px;
    }

    textarea {
        height: 106px;
        resize: none;
    }
    .react-datepicker-wrapper {
        display: block;
    }
  
    
    button {
        height: 36px;
        background-color: black;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        margin-top: 12px;
        cursor: pointer;
        float: right;
    }
  }
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
    margin-right: 17px;
  }

    
}
  
  
  thead,
  tfoot {
      font-size: 14px;
      color: $noir;
      border: none;
  }
  
  tbody {
      background-color: #e4f0f5;
  }
  
  caption {
      padding: 10px;
      caption-side: bottom;
  }
  
  table {
    margin: 10px;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid rgb(208, 212, 228);
    font-size: 15px;
    border-radius: 4px;
  }

  td,
  th {
    border: none;
      padding: 5px 10px;
      height: 36px;
      
      span {
        font-size: 12px;
        color: $light;
        font-weight: 300;
        display: block;
    }
  }
  
  .columnResizer {
    width: 1px;
    min-width: 1px;
    max-width: 1px;
    padding: 0 0 0 0;
    border: none;
  }
  
  .element-title {
    font-size: 14px !important;
    letter-spacing: normal;
  }
  
  
  
  tbody {
      background-color: #e4f0f5;
      border-bottom: none;
  }
  
  caption {
      padding: 10px;
      caption-side: bottom;
  }
  th {
      color: rgb(103, 104, 121);
      text-align: center;
      font-weight: normal;
  }
  
  td {
      text-align: center;
  }

th, td {
    border-right: 1px solid rgb(208, 212, 228);
    border-bottom: 1px solid rgb(208, 212, 228);
}

th:last-child, td:last-child  {
    border-right: none;
}

tr:last-child td {
    border-bottom: none;
}

tfoot {
    border: none;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active, .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
    color: $violet;
}

.text-rouge {
    color: var(--negative-color) !important;
}
.text-orange {
    color: #F2904A !important;
}
.text-noir {
    color: var(--primary-text-color) !important;
}
.text-vert {
    color: var(--positive-color) !important;
}

.lvl_2 {
    border: 1px solid green;
}

.lvl_2:first-child, .lvl_3:first-child, .lvl_4:first-child, .lvl_5:first-child {
    margin-top: 10px;
}
.lvl_2:last-child, .lvl_3:last-child, .lvl_4:last-child, .lvl_5:last-child {
    margin-bottom: 10px;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.titleTeam {
    margin: 40px 0 0;
}

.subitem-trait {
    border-left: 5px solid rgb(208, 212, 228);
}

.sub-element {
    position: relative;
        td:first-child:before {
            content: "";
            position: absolute;
            border: 1px solid rgb(208, 212, 228);
            border-top: none;
            border-right: none;
            border-bottom-left-radius: 8px;
            top: 50%;
            left: -25px;
            width: 20px;
    }
}

.multiselection {
    width: 300px;
    height: 36px !important;
    border-radius: 6px;
    border: 1px solid var(--layout-border-color) !important;
    z-index: 2;
    div {
        &:not(.dropdown-menu-wrapper){
            height: 100% !important;
        }
    }
}


.modal-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2.5em 1.5em 1.5em 1.5em;
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media screen and (min-width: 500px) {
    /* Center the Modal! */
    .modal-area {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 30em;
      max-height: calc(100% - 1em);
    }
  }
  
  ._modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
  }
  
  ._modal-close-icon {
    width: 25px;
    height: 25px;
    fill: transparent;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 2;
  }
  
  .modal-body {
    padding-top: 0.25em;
  }
  ._hide-visual {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
  
  .scroll-lock {
    overflow: hidden;
    margin-right: 17px;
  }

  .chips--wrapper {
    display: flex;
  }

.alert-tooltip {
    display: inline-flex;
}
.monday-style-dialog-content-wrapper {
    z-index: 2;
}